












































































































import Vue from 'vue';
import { SearchProps } from '@/components/search/newsearch/types';
import HealthProfessionalUtils, {
  HealthProfessional,
} from '@/utils/HealthProfessionalUtils';
import PointOfServiceUtils, {
  PointOfService,
} from '@/utils/PointOfServiceUtils';
import ServiceCharacteristicUtils, {
  ServiceCharacteristic,
  ServiceHolder,
} from '@/utils/ServiceCharacteristicUtils';
import ProviderUtils, { Provider } from '@/utils/ProviderUtils';
import { Service } from '@/utils/ServiceUtils';
import HealthProfessionalResult from '@/components/search/newsearch/HealthProfessionalResult.vue';

export default Vue.extend({
  components: { HealthProfessionalResult },
  props: {
    value: {
      type: Object as () => SearchProps,
    },
  },

  computed: {
    selected(): any {
      return this.value.item.selected ? this.value.item.selected[0] : undefined;
    },

    uri(): string | undefined {
      const posUri = this.pointOfService?.uri
        ? this.$langobj.parse(this.pointOfService.uri)
        : undefined;
      const providerUri = this.provider?.uri
        ? this.$langobj.parse(this.provider.uri)
        : undefined;

      return posUri || providerUri || undefined;
    },

    phone_number(): string | undefined {
      return (
        this.pointOfService?.phone_number ||
        this.provider?.phone_number ||
        undefined
      );
    },

    name(): string | undefined {
      if (this.provider?.name_long) {
        return this.$langobj.parse(this.provider?.name_long);
      }
      return this.pointOfService?.name
        ? this.$langobj.parse(this.pointOfService.name)
        : undefined;
    },

    services(): Partial<Service>[] | undefined {
      let localServices = this.pointOfService?.override_provider_services
        ? this.pointOfService?.services
        : this.provider?.services;

      localServices = localServices?.filter(
        (a: Partial<Service>) => a.hidden != true
      );

      return localServices?.sort((a: Partial<Service>, b: Partial<Service>) => {
        const localA = this.$langobj.parse(a.name);
        const localB = this.$langobj.parse(b.name);
        return localA && localB ? localA.localeCompare(localB) : 0;
      });
    },

    servicesByCharacteristic(): Partial<ServiceHolder>[] | undefined {
      const holders: Partial<ServiceHolder>[] = [];

      this.characteristics?.forEach((item) => {
        holders.push({
          characteristic: item,
          items: [] as Partial<Service>[],
        });
      });
      const others = holders.find((e) => e.characteristic?.key == 'other');

      this.services?.forEach((service) => {
        if (
          service.service_characteristics &&
          service.service_characteristics.length > 0
        ) {
          service.service_characteristics?.forEach((charac) => {
            const holder = holders.find(
              (e) => e.characteristic?.key == charac.key
            );
            holder ? holder.items?.push(service) : others?.items?.push(service);
          });
        } else {
          others?.items?.push(service);
        }
      });

      return holders;
    },

    language_designation(): string | undefined {
      return this.pointOfService?.language_designation?.id &&
        this.pointOfService?.language_designation?.name
        ? this.$langobj.parse(this.pointOfService.language_designation.name)
        : this.provider?.language_designation?.name
        ? this.$langobj.parse(this.provider.language_designation.name)
        : undefined;
    },

    description(): string | undefined {
      if (
        this.pointOfService?.description &&
        this.$langobj.parse(this.pointOfService?.description)
      ) {
        return this.$langobj.parse(this.pointOfService?.description);
      } else if (
        this.provider?.description &&
        this.$langobj.parse(this.provider?.description)
      ) {
        return this.$langobj.parse(this.provider?.description);
      } else {
        return undefined;
      }
    },
  },

  data(): {
    pointOfService?: PointOfService;
    provider?: Provider;
    health_professionals?: HealthProfessional[];
    characteristics?: ServiceCharacteristic[];
    loading: boolean;
    edit: {
      visible: boolean;
    };
  } {
    return {
      pointOfService: undefined,
      provider: undefined,
      health_professionals: undefined,
      characteristics: undefined,
      loading: false,
      edit: {
        visible: false,
      },
    };
  },

  watch: {
    'selected.id': {
      immediate: true,
      async handler() {
        this.loading = true;
        await this.loadCharacteristics();
        await this.loadPointOfService();
        await this.loadProvider();
        await this.loadHealthProfessionals();
        this.loading = false;
      },
    },
  },

  methods: {
    cancel() {
      this.$emit('item_selected', undefined);
    },

    async loadPointOfService() {
      try {
        this.pointOfService = undefined;
        if (this.selected?.data?.point_of_service?.id) {
          const item = await PointOfServiceUtils.api.get(
            this.selected?.data?.point_of_service?.id
          );
          this.pointOfService =
            PointOfServiceUtils.pointOfService.generateDefault(
              item
            ) as PointOfService;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async loadProvider() {
      try {
        this.provider = undefined;
        if (this.pointOfService?.service_provider?.id) {
          const item = await ProviderUtils.api.get(
            this.pointOfService?.service_provider?.id
          );
          this.provider = ProviderUtils.provider.generateDefault(
            item
          ) as Provider;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async loadHealthProfessionals() {
      try {
        this.health_professionals = undefined;
        const list = await HealthProfessionalUtils.api.list({
          point_of_service_id: this.pointOfService?.id,
        });
        this.health_professionals = list.map(
          (item) =>
            HealthProfessionalUtils.healthProfessional.generateDefault(
              item
            ) as HealthProfessional
        );
      } catch (error) {
        console.error(error);
      }
    },

    async loadCharacteristics() {
      try {
        this.characteristics =
          await ServiceCharacteristicUtils.serviceCharacteristic.api.list();
      } catch (error) {
        console.error(error);
      }
    },

    async handleEdit(item: PointOfService) {
      await this.$router.push({
        name: 'pointofservice-edit',
        params: { id: item.id.toString() },
      });
    },
  },
});
