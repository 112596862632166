



























import Vue from 'vue';
import { SearchProps } from '@/components/search/newsearch/types';
import SearchForm from '@/components/search/newsearch/SearchForm.vue';
import SearchMap from '@/components/search/newsearch/SearchMap.vue';
import SearchResults from '@/components/search/newsearch/SearchResults.vue';
import SearchItem from '@/components/search/newsearch/SearchItem.vue';

export default Vue.extend({
  components: {
    SearchForm,
    SearchMap,
    SearchResults,
    SearchItem,
  },

  data(): {
    internal: SearchProps; // data shared across all components
  } {
    return {
      internal: {
        items: undefined,
        item: {
          selected: undefined,
          hover: undefined,
        },
        location: undefined,
        map: {
          view: undefined,
        },
      },
    };
  },

  methods: {
    handleItems(value: any) {
      this.internal.items = Object.freeze(value);
    },

    handleLocation(value: any) {
      this.internal.location = value;
    },

    handleSelected(value: any) {
      this.internal.item.selected = value;
    },

    handleHover(value: any) {
      this.internal.item.hover = value;
    },
  },
});
