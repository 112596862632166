










import Vue from 'vue';
import SearchOverlay from './SearchOverlay.vue';
import { SearchProps } from '@/components/search/newsearch/types';
import { v4 as uuidv4 } from 'uuid';
import { debounce } from 'lodash-es';
import { Feature, Map, MapBrowserEvent, View } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import TileLayer from 'ol/layer/Tile';
import { Cluster, OSM, Vector } from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { extend, Extent } from 'ol/extent';
import CircleStyle from 'ol/style/Circle';
import Layer from 'ol/layer/Layer';
import { fromLonLat } from 'ol/proj';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import MarkerUtils from '@/utils/MarkerUtils';

// const icons: { [key: string]: Style } = {};

export default Vue.extend({
  components: {
    SearchOverlay,
  },

  props: {
    value: {
      type: Object as () => SearchProps,
    },
  },

  data(): {
    id: string;
    map?: Map;
    user: {
      source?: Vector;
      layer?: VectorLayer;
    };
    items: {
      source?: Vector;
      layer?: VectorLayer;
    };
  } {
    return {
      id: uuidv4(),
      map: undefined,
      user: {
        source: undefined,
        layer: undefined,
      },
      items: {
        source: undefined,
        layer: undefined,
      },
    };
  },

  computed: {
    hover(): any {
      return this.value.item.hover;
      // return this.value.item.hover?.length === 1
      //   ? this.value.item.hover[0]
      //   : undefined;
    },
  },

  async mounted() {
    this.map = new Map({
      target: this.id,
      layers: [new TileLayer({ source: new OSM() })],
      view: new View({
        projection: 'EPSG:3857',
        center: fromLonLat(JSON.parse(process.env.VUE_APP_LOCATION_DEFAULT)),
        zoom: process.env.VUE_APP_LOCATION_ZOOM,
      }),
    });

    this.user.source = new Vector({
      features: [],
    });
    this.user.layer = new VectorLayer({
      source: new Cluster({
        distance: 0, // distance 0 turns clustering off
        source: this.user.source,
      }),
      zIndex: 100,
      style: () => {
        return new Style({
          image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
              color: '#fff',
            }),
            fill: new Fill({
              color: '#226cbf',
            }),
          }),
        });
      },
    });
    this.user.layer.setProperties({
      name: 'user_location',
    });
    this.map.addLayer(this.user.layer);

    this.items.source = new Vector({
      features: [],
    });
    this.items.layer = new VectorLayer({
      source: new Cluster({
        distance: 15,
        source: this.items.source,
      }),
      style: (feature?: any) => {
        const size = feature?.getProperties().features?.length;

        const isHover = this.value.item?.hover
          ? feature?.getProperties().features?.some((feature: Feature) => {
              const id = feature.getProperties().data.point_of_service.id;
              return this.value.item?.hover?.some(
                (hover) => hover.data.point_of_service.id === id
              );
            })
          : false;

        const isSelect = this.value.item?.selected
          ? feature?.getProperties().features?.some((feature: Feature) => {
              const id = feature.getProperties().data.point_of_service.id;
              return this.value.item?.selected?.some(
                (selected) => selected.data.point_of_service.id === id
              );
            })
          : false;

        // const isFrench = feature
        //   ?.getProperties()
        //   .features?.some((feature: Feature) => {
        //     const fr =
        //       feature.getProperties().data.point_of_service.language_services
        //         ?.fr;
        //     return !!fr;
        //   });

        const pos = feature?.getProperties().features?.[0].getProperties()
          ?.data?.point_of_service;
        // console.log(size,pos);
        const sp = feature?.getProperties().features?.[0].getProperties()
          ?.data?.service_providers;

        // generate a key to cache the specific style for the point to improve performance
        // const key = `points_of_service${size}_${isHover}_${isSelect}_${isFrench}`;
        // if (!icons[key]) {
        let icon = undefined;
        // style has not been generated for the key so generate it now
        if (size > 1) {
          const fontSize = 10 * (isHover ? 1.4 : 1) * (size > 99 ? 0.75 : 1);

          icon = new Style({
            image: new CircleStyle({
              radius: isHover ? 13 : 10,
              stroke: new Stroke({
                color: '#fff',
                width: isHover ? 2 : 1,
              }),
              fill: new Fill({
                color:
                  isHover && isSelect
                    ? '#267AD9'
                    : isHover
                    ? '#639FE3'
                    : isSelect
                    ? '#1D5CA5'
                    : '#4D92E0',
              }),
            }),
            text: new Text({
              text: size.toString(),
              font: fontSize + 'px sans-serif',
              fill: new Fill({
                color: '#fff',
              }),
              offsetY: 1,
            }),
          });
        } else {
          // const iconSrc = isFrench
          //   ? require('@/assets/Designated-marker.png')
          //   : require('@/assets/Non-Identified-marker.png');
          const iconSrc = MarkerUtils.marker.iconSrcForPos(pos, sp);
          icon = new Style({
            image: new Icon({
              anchor: [0.5, 1],
              anchorXUnits: IconAnchorUnits.FRACTION,
              anchorYUnits: IconAnchorUnits.FRACTION,
              scale: isHover ? [1, 1] : [0.8, 0.8],
              src: iconSrc,
            }),
          });
        }

        return icon;
      },
    });
    this.items.layer.setProperties({
      name: 'points_of_service',
    });
    this.map.addLayer(this.items.layer);

    this.map.on('singleclick', (evt: MapBrowserEvent) => {
      let selected: any[] = this.value.item?.selected || [];
      let extents: Extent[] = [];

      this.map?.forEachFeatureAtPixel(
        evt.pixel,
        (feature: any) => {
          // the outside feature is always a cluster feature, set the containing feature array as the actual value
          selected = feature
            ?.getProperties()
            .features?.map((feature: Feature) =>
              this.value.items?.find(
                (item) => item.id === feature.getProperties().id
              )
            );

          // store the feature extents so the view can be moved to contain them all
          extents = feature
            ?.getProperties()
            .features?.map((feature: Feature) =>
              feature.getGeometry()?.getExtent()
            )
            .filter((extent: Extent) => !!extent);
        },
        {
          layerFilter: (layer: Layer) => {
            return layer?.getProperties()?.name === 'points_of_service';
          },
        }
      );

      if (selected.length > 1) {
        // when there is more than one feature in the cluster zoom into the cluster to view them
        const extent = extents.pop() as Extent;
        while (extents.length > 0) {
          extend(extent, extents.pop() as Extent);
        }

        this.map?.updateSize(); // update map size for calculation

        this.map?.getView()?.fit(extent, {
          padding: [300, 300, 300, 300],
          maxZoom: 18,
          size: this.map?.getSize(),
        });
      } else {
        this.$emit('item_selected', selected.length > 0 ? selected : undefined);
      }
    });

    this.map.on(
      'pointermove',
      debounce((evt: MapBrowserEvent) => {
        let hover: any[] = [];

        // check if overlay has mouseover
        let node = evt.originalEvent.target as HTMLElement;
        let overlay;
        while (node.parentElement && !overlay) {
          overlay = node.parentElement?.classList?.contains('overlay')
            ? node.parentElement
            : undefined;
          node = node.parentElement;
        }

        if (overlay) {
          return; // do nothing
        }

        this.map?.forEachFeatureAtPixel(
          evt.pixel,
          (feature: any) => {
            // the outside feature is always a cluster feature, set the containing feature array as the actual value
            hover = feature
              ?.getProperties()
              .features?.map((feature: Feature) =>
                this.value.items?.find(
                  (item) => item.id === feature.getProperties().id
                )
              );
          },
          {
            layerFilter: (layer: Layer) => {
              return layer?.getProperties()?.name === 'points_of_service';
            },
          }
        );

        this.$emit('item_hover', hover.length > 0 ? hover : undefined);
      }, 5)
    );
  },

  watch: {
    'value.items': {
      handler(value: any) {
        this.items.source?.clear();

        const features = (value || [])
          .filter((item: any) => item.geojson != null)
          .map((item: any) => {
            return new Feature({
              geometry: new GeoJSON({
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:3857',
              }).readGeometry(item.geojson),
              id: item.id,
              data: {
                ...item.data,
              },
            });
          });

        this.items.source?.addFeatures(features);

        if (features?.length > 0) {
          const clone: any[] = Array.from(features);
          const extent = clone.pop().getGeometry().getExtent();
          while (clone.length > 0) {
            extend(extent, clone.pop().getGeometry().getExtent());
          }

          this.map?.updateSize(); // update map size for calculation
          this.map?.getView()?.fit(extent, {
            padding: [300, 300, 300, 300],
            maxZoom: 18,
            size: this.map?.getSize(),
          });
        }
      },
    },

    'value.item.selected': {
      handler() {
        this.map?.updateSize(); // update map size for calculation
        this.items.layer?.changed(); // rerender map for changes
      },
    },

    'value.item.hover': {
      handler() {
        this.map?.updateSize(); // update map size for calculation
        this.items.layer?.changed(); // rerender map for changes
      },
    },

    'value.location': {
      handler(value) {
        this.user.source?.clear();

        if (value) {
          this.user.source?.addFeature(
            new Feature({
              geometry: new GeoJSON({
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:3857',
              }).readGeometry(value),
            })
          );
        }

        this.map?.setView(
          new View({
            projection: 'EPSG:3857',
            center: fromLonLat(
              value?.coordinates
                ? value.coordinates
                : JSON.parse(process.env.VUE_APP_LOCATION_DEFAULT)
            ),
            zoom: value?.coordinates ? 13 : process.env.VUE_APP_LOCATION_ZOOM,
          })
        );
      },
    },
  },
});
