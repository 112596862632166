






















































import Vue from 'vue';
import { Map, Overlay } from 'ol';
import { v4 as uuidv4 } from 'uuid';
import OverlayPositioning from 'ol/OverlayPositioning';
import { fromLonLat } from 'ol/proj';
import HealthProfessionalUtils, {
  HealthProfessional,
} from '@/utils/HealthProfessionalUtils';

export default Vue.extend({
  props: {
    value: {
      type: Array as () => any[],
    },

    map: {
      type: Object as () => Map,
    },
  },

  data(): {
    id: string;
    overlay: Overlay;
  } {
    return {
      id: uuidv4(),
      overlay: new Overlay({
        positioning: OverlayPositioning.TOP_LEFT,
        stopEvent: true,
      }),
    };
  },

  mounted() {
    this.overlay.setElement(document.getElementById(this.id) || undefined);
  },

  methods: {
    formatName(name: HealthProfessional['name']) {
      return HealthProfessionalUtils.healthProfessional.formatName(name);
    },
  },

  watch: {
    value: {
      handler(value) {
        if (value && value[0].geojson != null) {
          const overlayRect = this.overlay
            ?.getElement()
            ?.getBoundingClientRect();
          const mapRect = this.map?.getTargetElement().getBoundingClientRect();

          if (overlayRect && mapRect) {
            // TODO: compare overlay and map to get an offset if it is located  outside the screen
            this.overlay?.setOffset([5, 5]);
            this.overlay?.setPosition(fromLonLat(value[0].geojson.coordinates));
          }

          this.map?.addOverlay(this.overlay);
        } else {
          this.map?.removeOverlay(this.overlay);
        }
      },
    },
  },
});
