<template>
  <div
    class="content"
    @click="handleClick(item)"
    @mouseenter="handleHover(item)"
    @mouseleave="handleHover(undefined)"
  >
    <div class="item-icon">
      <i
        aria-hidden="true"
        class="v-icon notranslate mdi theme--light"
        :class="iconName"
      />
    </div>
    <div class="item-text">
      <div class="item-name">
        <span>{{ $langobj.parse(item.data.point_of_service.name) }}</span>
      </div>
      <div class="item-address">
        {{
          item.data.service_providers && item.data.service_providers.name_long
            ? $langobj.parse(item.data.service_providers.name_long)
            : undefined
        }}
      </div>
      <div class="item-address">
        {{ item.data.point_of_service.address }}
      </div>
    </div>
    <div class="item-right">
      <div class="item-distance" v-if="item.distance">
        {{ formattedDistance }}
      </div>
      <span
        :style="{ fontWeight: 'bold', fontSize: '0.9em', color: frIconColor }"
        v-if="
          item.data.point_of_service.language_services &&
          item.data.point_of_service.language_services.fr
        "
        ><v-icon small :color="frIconColor">$fleurDeLys</v-icon>
        <span>{{ item.data.service_providers.language_designation_key }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  props: {
    item: Object,
    handleClick: Function,
    handleHover: Function,
  },
  computed: {
    frIconColor() {
      if (this.item.data.service_providers.language_designation_key == 'DC')
        return '#006400';
      return '#216bbf';
    },
    iconName() {
      const name = this.item?.data?.point_of_service?.name
        ? this.$langobj.parse(this.item.data.point_of_service.name)
        : undefined;
      if (name == undefined) return '';
      if (name.match(/Hospital/)) return 'mdi-hospital-building';
      if (name.match(/^Dr/)) return 'mdi-doctor';
      return 'mdi-office-building-marker';
    },
    spName() {
      return this.item?.data.service_providers &&
        this.item?.data.service_providers.name_long
        ? this.$langobj.parse(this.item.data.service_providers.name_long)
        : undefined;
    },
    formattedDistance() {
      const result = Intl.NumberFormat(this.$i18n.locale, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }).format(this.item.distance / 1000);
      return `${result} km`;
    },
  },
});
</script>

<style scoped lang="scss">
.content {
  min-height: 65px;
  width: 100%;
  // margin: 7px 10px;
  padding: 5px 10px;
  display: flex;
  border-bottom: 1px rgba(0, 0, 0, 0.12) solid;

  &:hover {
    background: #eee;
    cursor: pointer;
  }

  .item-icon {
    min-width: 35px;
    margin-right: 0;

    i {
      color: #ccc;
    }
  }

  .item-text {
    .item-name {
      // padding-top: 4px;
      font-size: 0.9em;
      line-height: 1.2;
    }
    .item-address {
      font-size: 0.7em;
      color: #666;
    }
  }

  .item-right {
    text-align: right;
    min-width: 15%;
    margin-left: auto;
    line-height: 1.2;

    .item-distance {
      font-size: 0.8em;
      font-weight: bold;
      color: #666;
      white-space: nowrap;
      padding-top: 2px;
      padding-left: 2px;
    }
    .item-language {
      background-color: #216bbf;
      color: white;
      padding: 0 4px;
      border-radius: 3px;
      font-size: 0.7em;
      // margin: 1px 0 0 7px;
    }
  }
}
</style>
