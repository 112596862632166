






























import Vue from 'vue';
import HealthProfessionalUtils, {
  HealthProfessional,
} from '@/utils/HealthProfessionalUtils';

export default Vue.extend({
  name: 'HealthProfessionalResult',
  props: {
    value: {
      type: Object as () => HealthProfessional,
    },
  },

  computed: {},

  data(): {
    // pointOfService?: PointOfService;
    // provider?: Provider;
    // health_professionals?: HealthProfessional[];
    // characteristics?: ServiceCharacteristic[];
    // loading: boolean;
    // edit: {
    //   visible: boolean;
    // };
  } {
    return {
      // pointOfService: undefined,
      // provider: undefined,
      // health_professionals: undefined,
      // characteristics: undefined,
      // loading: false,
      // edit: {
      //   visible: false,
      // },
    };
  },

  methods: {
    formatName(name: HealthProfessional['name']) {
      return HealthProfessionalUtils.healthProfessional.formatName(name);
    },
  },
});
