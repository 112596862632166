















































import Vue from 'vue';
import { SearchProps } from '@/components/search/newsearch/types';
import SearchResultItem from '@/components/search/newsearch/SearchResultItem.vue';
import UserAgreementUtils from '@/utils/UserAgreementUtils';
import { LanguageObject } from '@/utils/LocaleUtils';

export default Vue.extend({
  components: {
    SearchResultItem,
  },

  props: {
    value: {
      type: Object as () => SearchProps,
    },
    height: {
      type: Number as () => number,
    },
  },

  data(): {
    page: number;
    itemsPerPage: number;
    firstItem: number;
    lastItem: number;
    contribution?: LanguageObject;
  } {
    return {
      page: 0,
      itemsPerPage: 20,
      firstItem: 0,
      lastItem: 19,
      contribution: undefined,
    };
  },

  computed: {
    pagedItems(): any {
      return !this.value.items
        ? undefined
        : this.value.items.length <= this.itemsPerPage
        ? this.value.items
        : this.value.items.slice(this.firstItem, this.lastItem);
    },
  },

  methods: {
    handleClick(item: any) {
      this.$emit('item_selected', item ? [item] : undefined);
    },

    handleHover(item: any) {
      this.$emit('item_hover', item ? [item] : undefined);
    },

    handlePage(delta: number) {
      const newPage = this.page + delta;
      if (
        this.value.items == undefined ||
        newPage < 0 ||
        newPage > this.value.items.length / this.itemsPerPage
      ) {
        return;
      }

      this.page = newPage < 0 ? 0 : newPage;
      this.firstItem = this.page * this.itemsPerPage;
      this.lastItem = (this.page + 1) * this.itemsPerPage - 1;
      if (this.lastItem > this.value.items.length - 1)
        this.lastItem = this.value.items.length - 1;
    },
    async getContributionText() {
      try {
        const agreement = await UserAgreementUtils.userAgreement.api.get(
          'contribution'
        );
        if (agreement) this.contribution = agreement.text;
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    'value.items': {
      immediate: true,
      handler() {
        this.page = 0;
        this.firstItem = 0;
        this.lastItem = this.firstItem + this.itemsPerPage - 1;
      },
    },
  },
  async mounted() {
    this.getContributionText();
  },
});
