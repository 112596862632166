import { render, staticRenderFns } from "./HealthProfessionalResult.vue?vue&type=template&id=5866ea7c&scoped=true&"
import script from "./HealthProfessionalResult.vue?vue&type=script&lang=ts&"
export * from "./HealthProfessionalResult.vue?vue&type=script&lang=ts&"
import style0 from "./HealthProfessionalResult.vue?vue&type=style&index=0&id=5866ea7c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5866ea7c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
