










































































































































import Vue from 'vue';
import { SearchProps } from '@/components/search/newsearch/types';
import Autocomplete from '@/components/search/Autocomplete.vue';
import { debounce } from 'lodash-es';
import SearchUtils from '@/utils/SearchUtils';
import { AutocompleteItem } from '../types';
import { GeometryObject } from 'geojson';
import PointOfServiceUtils from '@/utils/PointOfServiceUtils';
import SectorsOfCareUtils, { SectorOfCare } from '@/utils/SectorsOfCareUtils';
import HealthTeamUtils, { HealthTeam } from '@/utils/HealthTeamUtils';
import CommunityUtils, { Community } from '@/utils/CommunityUtils';

export default Vue.extend({
  components: { Autocomplete },

  props: {
    value: {
      type: Object as () => SearchProps,
    },
  },

  computed: {
    language_service_items() {
      return PointOfServiceUtils.language_service_keys.map((key) => ({
        text: this.$tc(`pointofservice.language_services.${key}`),
        value: key,
      }));
    },

    language_services_switch: {
      get(): boolean {
        return this.language_services.length > 0;
      },
      set(value: boolean) {
        this.language_services = value ? ['fr'] : [];
      },
    },

    is_filtered(): boolean {
      return (
        this.language_services.length > 0 ||
        this.search_ht.length > 0 ||
        this.search_ht.length > 0 ||
        this.search_soc.length > 0 ||
        this.search_communities.length > 0
      );
    },
    communitiesSorted(): Community[] {
      return this.communities?.slice().sort((a: Community, b: Community) => {
        const localA = this.$langobj.parse(a.name);
        const localB = this.$langobj.parse(b.name);
        return localA && localB ? localA.localeCompare(localB) : 0;
      });
    },
  },

  data(): {
    internal?: any[];
    search?: string;
    useCurrentLocation: boolean;
    address: {
      text?: string;
      geojson?: GeometryObject;
      autocomplete?: AutocompleteItem[];
    };
    language_services: string[];
    waiting: boolean;

    sectors_of_care: SectorOfCare[];
    search_soc: SectorOfCare[];
    health_teams: HealthTeam[];
    search_ht: HealthTeam[];
    communities: Community[];
    search_communities: Community[];
    visible: boolean;
  } {
    return {
      search: undefined,
      useCurrentLocation: false,
      internal: undefined,
      address: {
        text: undefined,
        geojson: undefined,
        autocomplete: undefined,
      },
      language_services: [],
      waiting: false,

      visible: false,
      sectors_of_care: [],
      search_soc: [],
      health_teams: [],
      search_ht: [],
      communities: [],
      search_communities: [],
    };
  },

  methods: {
    updateItems() {
      if (this.search == undefined) {
        this.search = '';
        this.handleSearch();
        return;
      }
      this.$emit(
        'items',
        this.internal?.filter((item) => {
          let valid = true;

          if (this.language_services?.length > 0) {
            valid = this.language_services?.some((key: string) => {
              return item.data.point_of_service.language_services
                ? item.data.point_of_service.language_services[key]
                : null;
            });
          }

          if (valid && this.search_soc.length > 0) {
            valid = this.search_soc.some((soc: Partial<SectorOfCare>) => {
              // first check in the point of service
              if (soc.key && item.data.point_of_service?.sectors_of_care)
                return item.data.point_of_service.sectors_of_care.includes(
                  soc.key
                );
              // then at the service provider level
              return soc.key && item.data.service_providers?.sectors_of_care
                ? item.data.service_providers?.sectors_of_care.includes(soc.key)
                : null;
            });
          }

          // if (valid && this.search_ht.length > 0) {
          //   valid = this.search_ht.some((ht: Partial<HealthTeam>) => {
          //     // service provider level only
          //     return ht.id && item.data.service_providers?.health_teams
          //       ? item.data.service_providers?.health_teams.includes(ht.id)
          //       : null;
          //   });
          // }

          if (valid && this.search_communities.length > 0) {
            valid = this.search_communities.some((comm: Partial<Community>) => {
              // point of service level only
              console.log(comm.id);
              return (
                comm.id && item.data.point_of_service?.community_id == comm.id
              );
            });
          }

          return valid;
        })
      );
    },

    handleSearch: debounce(async function (this: any) {
      try {
        this.waiting = true;
        let items =
          this.search !== undefined
            ? await SearchUtils.api.search({
                text: this.search,
                geojson: this.address.geojson,
              })
            : undefined;

        this.waiting = false;
        this.internal = items;
        this.updateItems();
      } catch (errors) {
        console.error(errors);
      }
    }, 250),

    handleSearchKeypress(event: KeyboardEvent) {
      if (event.code === 'Enter') {
        this.handleSearch();
      }
    },

    resetFilter() {
      this.search_soc = [];
      this.search_ht = [];
      this.search_communities = [];
      this.language_services_switch = false;
    },

    removeSearchSocItem(item: SectorOfCare) {
      this.search_soc.splice(this.search_soc.indexOf(item), 1);
    },

    removeSearchCommItem(item: Community) {
      this.search_communities.splice(this.search_communities.indexOf(item), 1);
    },

    async handleAutocomplete(value: string) {
      if (value) {
        const resp = await SearchUtils.api.address.autocomplete(value);
        this.address.autocomplete = resp.map((address) => ({
          key: address,
          value: address,
        }));
      } else {
        this.address.autocomplete = undefined;
      }
    },

    async handleCurrentLocation() {
      this.useCurrentLocation = true;
      const position = await this.getCurrentPosition();

      this.address.text = undefined;
      this.address.geojson = {
        type: 'Point',
        coordinates: [position.coords.longitude, position.coords.latitude],
      };

      this.$emit('geojson', this.address.geojson);
      this.handleSearch();
    },

    async getCurrentPosition() {
      return await new Promise<any>((resolve) => {
        navigator.geolocation.getCurrentPosition(
          (position) => resolve(position),
          () => resolve(undefined)
        );
      });
    },
  },

  watch: {
    'address.text': {
      immediate: true,
      async handler(value, oldValue) {
        if (value == oldValue) return;
        if (value) {
          this.useCurrentLocation = false;
          const resp = await SearchUtils.api.address.geocode(value);
          this.address.geojson = {
            type: 'Point',
            coordinates: [resp.longt, resp.latt],
          };
        } else if (!this.useCurrentLocation) {
          this.address.geojson = undefined;
        }

        this.$emit('geojson', this.address.geojson);
        this.handleSearch();
      },
    },

    language_services: {
      handler() {
        this.updateItems();
      },
    },
    search_soc: {
      handler() {
        this.updateItems();
      },
    },
    search_ht: {
      handler() {
        this.updateItems();
      },
    },
    search_communities: {
      handler() {
        this.updateItems();
      },
    },
  },

  async mounted() {
    try {
      this.sectors_of_care = await SectorsOfCareUtils.sectorOfCare.api.list();
      this.health_teams = await HealthTeamUtils.healthTeam.api.list();
      this.communities = await CommunityUtils.api.list();
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  },
});
